<template>
  <div class="d-flex flex-column gap-4">
    <v-card class="rounded-xl header-size">
      <video-background :src="videoCover" class="header-size">
        <div
          class="d-flex flex-column justify-space-between py-8 px-12 rounded-xl header-size"
        >
          <v-row no-gutters>
            <v-col cols="12" sm="7" md="6" lg="5">
              <div class="pb-8">
                <h3 class="white--text">Identidade Visual da Plataforma</h3>
              </div>
            </v-col>
          </v-row>
          <div
            class="d-flex align-center w-full"
            :style="{ gap: $vuetify.breakpoint.xsOnly ? '' : '0.5rem' }"
          >
            <v-card elevation="0">
              <v-btn
                :disabled="!!loadingDownload"
                :loading="loadingDownload === 'videoCover'"
                color="primary"
                @click="download('videoCover', videoCover)"
                :block="$vuetify.breakpoint.xsOnly"
              >
                <v-icon small left>mdi-download</v-icon>
                Vídeo de Capa
              </v-btn>
            </v-card>
          </div>
        </div>
      </video-background>
    </v-card>
    <section class="my-3 mx-2">
      <v-card
        :elevation="0"
        rounded="lg"
        v-for="(theme, i) in themes"
        :key="theme"
        :dark="theme === 'dark'"
        :light="theme === 'light'"
      >
        <v-row no-gutters class="rounded-lg mx-0">
          <v-col
            v-for="(color, j) in colors"
            :key="color.key"
            :sm="color.size"
            cols="4"
            align-self="stretch"
          >
            <v-card
              :dark="theme === 'dark'"
              :light="theme === 'light'"
              :elevation="0"
              rounded="0"
              class="pb-1 pt-8 h-full d-flex flex-column justify-end align-sm-end align-center"
              :class="{
                'rounded-tl-lg': j === 0 && i === 0,
                'rounded-tr-lg': j === colors.length - 1 && i === 0,
                'rounded-bl-lg': i === themes.length - 1 && j === 0,
                'rounded-br-lg':
                  i === themes.length - 1 && j === colors.length - 1,
              }"
              :style="{
                backgroundColor: themeConfig[theme][color.key],
              }"
            >
              <p class="mb-0 font-weight-medium lh-1 mr-sm-4">
                {{ color.name }}
              </p>
              <v-btn
                :disabled="blockCopy == themeConfig[theme][color.key]"
                text
                @click="copy(themeConfig[theme][color.key])"
              >
                {{
                  blockCopy == themeConfig[theme][color.key]
                    ? "Copiado"
                    : themeConfig[theme][color.key]
                }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
        <div style="position: absolute; top: 0px; left: 0px" class="px-5">
          <h5
            class="text-overline font-weight-black mb-0 white--text"
            style="opacity: 0.7"
          >
            {{ theme === "light" ? "Tema Claro" : "Tema Escuro" }}
          </h5>
        </div>
      </v-card>
    </section>

    <v-card elevation="0" rounded="xl">
      <v-row no-gutters class="rounded-xl mx-0">
        <v-col cols="12" sm="6">
          <v-card
            class="white pa-12 pb-6 d-flex flex-column gap-3 justify-center align-center"
            style="min-height: 250px"
            elevation="0"
            rounded="0"
            :dark="false"
            :light="true"
            :class="{
              'rounded-l-xl': $vuetify.breakpoint.smAndUp,
              'rounded-t-xl': $vuetify.breakpoint.xsOnly,
            }"
          >
            <img
              class="ma-0"
              src="@/assets/images/logo/logo-dark.png"
              alt
              :height="$vuetify.breakpoint.smAndUp ? '45' : '35'"
              style="cursor: pointer"
            />
            <v-btn
              :disabled="!!loadingDownload"
              :loading="loadingDownload === 'logo-dark'"
              @click="download('logo-dark', logos.dark)"
              text
              small
            >
              <v-icon small left>mdi-download</v-icon>
              Logo Escura
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
            class="pa-12 pb-6 d-flex flex-column gap-3 justify-center align-center"
            style="min-height: 250px"
            :color="appBarColor"
            elevation="0"
            rounded="0"
            :dark="true"
            :light="false"
            :class="{
              'rounded-r-xl': $vuetify.breakpoint.smAndUp,
              'rounded-b-xl': $vuetify.breakpoint.xsOnly,
            }"
          >
            <img
              class="ma-0"
              src="@/assets/images/logo/logo-light.png"
              alt
              :height="$vuetify.breakpoint.smAndUp ? '45' : '35'"
              style="cursor: pointer"
            />
            <v-btn
              :disabled="!!loadingDownload"
              :loading="loadingDownload === 'logo-light'"
              @click="download('logo-light', logos.light)"
              text
              small
            >
              <v-icon small left>mdi-download</v-icon>
              Logo Clara
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <section>
      <v-row align="stretch">
        <v-col cols="12" sm="4">
          <v-card rounded="xl" elevation="0">
            <div class="pa-4 pb-8">
              <div class="d-flex justify-center align-center">
                <div class="rounded" style="position: relative">
                  <qrcode-vue
                    style="
                      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
                      background-size: cover;
                    "
                    class="d-flex justify-center pa-5 ma-1 white rounded"
                    :value="qrCodeLink"
                    size="260"
                    level="M"
                  />
                  <div
                    style="
                      position: absolute;
                      top: 0px;
                      height: 100%;
                      width: 100%;
                    "
                    class="d-flex justify-center align-center"
                  >
                    <div
                      style="width: 36px; height: 36px; align-content: center"
                      class="white rounded-lg"
                    >
                      <v-img src="/favicon.png" contain height="36" />
                    </div>
                  </div>
                </div>
              </div>
              <v-alert
                color="info"
                class="mb-0 mt-2 mx-6"
                text
                dense
                border="bottom"
              >
                <v-icon class="mr-1">mdi-ticket</v-icon>
                Ingresso já utilizado
              </v-alert>
            </div>
            <ticket-dots bottom />
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="d-flex pa-5 flex-column h-full justify-center">
            <h2>
              {{ name }}
            </h2>
            <p>
              {{ texts.footerDescription }}
            </p>
            <div class="d-flex gap-2 flex-wrap">
              <v-chip
                v-for="chip in chipColor"
                :key="chip.name"
                label
                :color="chip.color"
              >
                {{ chip.name }}
              </v-chip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="card" style="pointer-events: none;">
            <party-item :timeStatus="false" :party="fakeParty"></party-item>
          </div>
        </v-col>
      </v-row>
    </section>
    <v-card rounded="lg" class="loaderBackground">
      <div class="loader" style="position: relative; height: 100%">
        <div class="logo">
          <img
            src="/favicon.png"
            height="40"
            width="40"
            style="border-radius: 50%"
            alt="TicketMe loading"
          />
        </div>
      </div>
      <div
        class="d-flex justify-center pb-2"
        style="position: absolute; bottom: 0px; left: 0px; right: 0px"
      >
        <v-btn
          :disabled="!!loadingDownload"
          :loading="loadingDownload === 'background'"
          dark
          text
          @click="download('background', '/img/logo/auth-background.png')"
          :block="$vuetify.breakpoint.xsOnly"
        >
          <v-icon small left>mdi-download</v-icon>
          Plano de Fundo
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  texts,
  legalInfo,
  themeWhite,
  themeDark,
  videoCover,
  appBarColor,
  logos,
  name,
} from "@/themeConfig.js";
import VideoBackground from "vue-responsive-video-background-player";
import TicketDots from "@/components/global/TicketDots.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
import QrcodeVue from "qrcode.vue";
import { colors } from "vuetify/lib";
export default {
  name: "Brand",
  components: { VideoBackground, QrcodeVue, TicketDots,PartyItem },
  data() {
    return {
      texts,
      legalInfo,
      name,
      themeWhite,
      themeDark,
      appBarColor,
      videoCover,
      logos,
      chipColor: [
        {
          name: "Sucesso",
          color: "success",
        },
        {
          name: "Erro",
          color: "error",
        },
        {
          name: "Alerta",
          color: "warning",
        },
        {
          name: "Informação",
          color: "info",
        },
      ],
      loadingDownload: false,
      blockCopy: false,
      themeConfig: {
        dark: themeDark,
        light: themeWhite,
      },
      themes: ["light", "dark"],
      colors: [
        {
          name: "Cor Primária",
          key: "primary",
          size: 3,
        },
        {
          name: "Cor Secundária",
          key: "secondary",
          size: 4,
        },
        {
          name: "Brand Primária",
          key: "brandPrimary",
          size: 5,
        },
      ],
       fakeParty: {
        name: "Festa de Exemplo",
        cover: "../img/BannerBrand.png",
        private: false,
        Organization: {
          name: "Organização",
          slug: "slug-da-organizacao",
          Platform: {
            domain: "dominio-da-organizacao.com",
            colors: {
              appBar: "blue"
            },
            logos: {
              light: "../img/logo/logo-light.png"
            }
          }
        },
        Address: {
          city: "Cidade",
          state: "Estado"
        },
        date: new Date(), 
        slug: "slug-da-festa",
        id: "id-da-festa"
      }
    };
  },
  methods: {
    textColor(hex) {
      const brightness = parseInt(hex.substring(1), 16);
      return brightness > 0xffffff / 2 ? "black" : "white";
    },
    copy(color) {
      navigator.clipboard.writeText(color);
      this.blockCopy = color;
      setTimeout(() => {
        this.blockCopy = false;
      }, 1000);
    },
    async download(name, fileUrl) {
      try {
        this.loadingDownload = name;
        const blob = await this.downloadImage(fileUrl);
        this.loadingDownload = false;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        this.$nextTick(() => {
          a.href = url;
          a.target = "_blank";
          a.download = this.fileName(name, blob);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDownload = false;
      }
    },
    async downloadImage(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    },
    fileName(name, blob) {
      return `${name}.${blob.type.split("/")[1]}`;
    },
  },
  computed: {
    qrCodeLink() {
      return window.location.origin;
    },
  },
};
</script>

<style scoped>
.transparent {
  background-color: transparent !important;
}

.fill-height {
  border-radius: 10px;
}
.header-size {
  height: 60vh !important;
  max-height: 300px !important;
}

.loaderBackground {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  min-height: 400px;
}
</style>
